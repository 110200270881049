import type { App } from "vue";
import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { createApolloProvider } from "@vue/apollo-option";

/** Adds target="_blank" to all a-tags that lead to an external site. */
function addTargetBlankToExternalLinks() {
    const links = document.querySelectorAll("a");
    links.forEach((link) => {
        try {
            const url = new URL(link.href);
            if (url.hostname !== window.location.hostname) {
                link.setAttribute("target", "_blank");
                link.setAttribute("rel", "noopener noreferrer");
            }
        } catch (e) {}
    });
}

const externalLinksMixin = {
    mounted() {
        addTargetBlankToExternalLinks();
    },
    updated() {
        addTargetBlankToExternalLinks();
    },
};

export default (app: App) => {
    if (import.meta.env.SSR) return;

    let url =
        import.meta.env.PUBLIC_GRAPHQL_URL ?? process.env.PUBLIC_GRAPHQL_URL;

    if (!url) {
        console.error("PUBLIC_GRAPHQL_URL is not defined in .env file");
        return;
    }

    const apolloProvider = createApolloProvider({
        defaultClient: new ApolloClient({
            uri: url,
            cache: new InMemoryCache(),
        }),
    });

    app.mixin(externalLinksMixin);
    app.use(apolloProvider);
};
